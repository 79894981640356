import { default as indexDDpJLqPBhmMeta } from "/app/pages/action/index.vue?macro=true";
import { default as _91id_93FlLm03CzZLMeta } from "/app/pages/adherents/[id].vue?macro=true";
import { default as indexbkX6wuincsMeta } from "/app/pages/adherents/index.vue?macro=true";
import { default as _91id_933RBKFoaHqYMeta } from "/app/pages/adhesion/[id].vue?macro=true";
import { default as _91id_93zAVVOYUmEQMeta } from "/app/pages/adhesion/clotures/[id].vue?macro=true";
import { default as indexGnzHNWwz3ZMeta } from "/app/pages/adhesion/clotures/index.vue?macro=true";
import { default as index8rXZatycdpMeta } from "/app/pages/adhesion/index.vue?macro=true";
import { default as _91id_93OdxkGm08MkMeta } from "/app/pages/adhesion/traitements/[id].vue?macro=true";
import { default as _91id_9350QlC9OmNNMeta } from "/app/pages/adhesion/traitements/files/[id].vue?macro=true";
import { default as index0gBOPTgyXNMeta } from "/app/pages/adhesion/traitements/index.vue?macro=true";
import { default as _91id_93fYSPh2mWKMMeta } from "/app/pages/adhesion/valides/[id].vue?macro=true";
import { default as index03PsXYp8W9Meta } from "/app/pages/adhesion/valides/index.vue?macro=true";
import { default as _91id_930KVdUhpH6VMeta } from "/app/pages/affectation/add/[id].vue?macro=true";
import { default as _91id_93fK3mcHjL2oMeta } from "/app/pages/affectation/edit/[id].vue?macro=true";
import { default as indexzCTtATjEr7Meta } from "/app/pages/affectation/index.vue?macro=true";
import { default as indexMNOwxhSHQZMeta } from "/app/pages/affilies/index.vue?macro=true";
import { default as index62PVvB1lWsMeta } from "/app/pages/avis-affilie/index.vue?macro=true";
import { default as _91id_93hq94yZmZ4dMeta } from "/app/pages/avis-embauche-debauche/[id].vue?macro=true";
import { default as _91id_93a7ZZImUuyWMeta } from "/app/pages/avis-embauche-debauche/historique-ae-ed/[id].vue?macro=true";
import { default as indexWo4LlXD7UsMeta } from "/app/pages/avis-embauche-debauche/historique-ae-ed/index.vue?macro=true";
import { default as indexlYolvEFBntMeta } from "/app/pages/avis-embauche-debauche/index.vue?macro=true";
import { default as index1cFf630yRDMeta } from "/app/pages/avis/index.vue?macro=true";
import { default as indexY9ODxP3l5eMeta } from "/app/pages/bulletin-consultation/index.vue?macro=true";
import { default as _91id_93X3Qj3bIzdZMeta } from "/app/pages/contenus/reglement-adherent/[id].vue?macro=true";
import { default as _91id_93mVnACYD4dEMeta } from "/app/pages/contenus/reglement-adherent/archive-in/[id].vue?macro=true";
import { default as index7omLMih84yMeta } from "/app/pages/contenus/reglement-adherent/archive-in/index.vue?macro=true";
import { default as indexSBXhEh5huLMeta } from "/app/pages/contenus/reglement-adherent/convocations/index.vue?macro=true";
import { default as index2tqyFOCN5aMeta } from "/app/pages/contenus/reglement-adherent/convocations/new/index.vue?macro=true";
import { default as indexVVGhb8rWj8Meta } from "/app/pages/contenus/reglement-adherent/index.vue?macro=true";
import { default as index_40old_41vg71sXIloeMeta } from "/app/pages/contenus/reglement-adherent/index(old).vue?macro=true";
import { default as _91id_93Y1aMBXbu0WMeta } from "/app/pages/contenus/reglement-adherent/poster/[id].vue?macro=true";
import { default as indexhi3IEy62RNMeta } from "/app/pages/contenus/reglement-adherent/poster/index.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91id_93ES9H7ESlMuMeta } from "/app/pages/dns/[id].vue?macro=true";
import { default as fichierFinderLxnncYy5DDMeta } from "/app/pages/dns/fichiers/fichierFinder.vue?macro=true";
import { default as indexwPTjHBhu1LMeta } from "/app/pages/dns/fichiers/index.vue?macro=true";
import { default as indexZkGoeDWHl8Meta } from "/app/pages/dns/index.vue?macro=true";
import { default as indexeVBKvJGJUFMeta } from "/app/pages/dns/paiements/index.vue?macro=true";
import { default as indexsHANlbWIo1Meta } from "/app/pages/dns/relances/index.vue?macro=true";
import { default as index7DWpj8IqIMMeta } from "/app/pages/edition-fdr/articles/create/index.vue?macro=true";
import { default as indexpAxxovZ16OMeta } from "/app/pages/edition-fdr/articles/index.vue?macro=true";
import { default as _91id_935jeapZcaJCMeta } from "/app/pages/edition-fdr/boite-risque/[id].vue?macro=true";
import { default as _91id_93LQ68SWiJdfMeta } from "/app/pages/edition-fdr/boites/[id].vue?macro=true";
import { default as _91id_93GT9WynZdFKMeta } from "/app/pages/edition-fdr/facteur-diagnostique/[id].vue?macro=true";
import { default as _91id_93PEx6wLQONvMeta } from "/app/pages/edition-fdr/facteur-risque/[id].vue?macro=true";
import { default as indexdwogxHYfH4Meta } from "/app/pages/edition-fdr/index.vue?macro=true";
import { default as editions_45fdrTC3tQRMNMIMeta } from "/app/pages/editions-fdr.vue?macro=true";
import { default as indexUh4D9BfWNsMeta } from "/app/pages/fdr/conseils/index.vue?macro=true";
import { default as _91id_934xz2e21JSpMeta } from "/app/pages/fdr/create/boite/[id].vue?macro=true";
import { default as _91idfdr_93iM65pwPyUMMeta } from "/app/pages/fdr/create/diagnostic/[idfdr].vue?macro=true";
import { default as _91id_93qVHDIhOUyvMeta } from "/app/pages/fdr/create/fdr/[id].vue?macro=true";
import { default as _91id_93Ty1hnLpC9XMeta } from "/app/pages/fdr/create/regle/[id].vue?macro=true";
import { default as indexE6FX2tPhldMeta } from "/app/pages/fdr/create/thematique/index.vue?macro=true";
import { default as _91idfdr_93ySZW4SoeNZMeta } from "/app/pages/fdr/diagnostic/[idfdr].vue?macro=true";
import { default as _91idthematique_93_45_91idfdr_93tUAlMkzbyNMeta } from "/app/pages/fdr/diagnostic/[idthematique]-[idfdr].vue?macro=true";
import { default as _91iddiagnostic_93lxs4LpnEPqMeta } from "/app/pages/fdr/diagnostic/create/[iddiagnostic].vue?macro=true";
import { default as _91iddiagnostic_93ENU0gYSjjMMeta } from "/app/pages/fdr/diagnostic/resiliation/[iddiagnostic].vue?macro=true";
import { default as indexRpy5j0t1ioMeta } from "/app/pages/fdr/fdr/index.vue?macro=true";
import { default as _91id_93HjHsVP3n7cMeta } from "/app/pages/fdr/point/boite/[id].vue?macro=true";
import { default as _91idboite_93iBQyrYsHQIMeta } from "/app/pages/fdr/point/point/[idboite].vue?macro=true";
import { default as index8TXKUfDr9sMeta } from "/app/pages/fdr/point/thematique/index.vue?macro=true";
import { default as _91id_9322LatRXwFTMeta } from "/app/pages/fdr/read/boite/[id].vue?macro=true";
import { default as _91idfdr_93ZQLhLcHsq5Meta } from "/app/pages/fdr/read/diagnostic/[idfdr].vue?macro=true";
import { default as _91id_93E6VDNx88BHMeta } from "/app/pages/fdr/read/fdr/[id].vue?macro=true";
import { default as _91iddiag_93i3sBuiXA7hMeta } from "/app/pages/fdr/read/regle/[iddiag].vue?macro=true";
import { default as _91id_93TxElgQ2VeaMeta } from "/app/pages/fdr/regle/[id].vue?macro=true";
import { default as indexDHNDcclCD4Meta } from "/app/pages/fdr/simulation/index.vue?macro=true";
import { default as _91idthematique_93sWconIEA1bMeta } from "/app/pages/fdr/tableau/[idthematique].vue?macro=true";
import { default as _91id_93o18MsErAtEMeta } from "/app/pages/fdr/thematiques/[id].vue?macro=true";
import { default as fiches_45conseil0I2A9JOziLMeta } from "/app/pages/fiches-conseil.vue?macro=true";
import { default as indexIg9GPKq5r8Meta } from "/app/pages/file/index.vue?macro=true";
import { default as _91id_93OjTdWVcZIlMeta } from "/app/pages/forgot-pwd/[id].vue?macro=true";
import { default as index_backup7feLI426f9Meta } from "/app/pages/forgot-pwd/index_backup.vue?macro=true";
import { default as indexCNvftuSas5Meta } from "/app/pages/forgot-pwd/index.vue?macro=true";
import { default as formationsF6ti9mewdHMeta } from "/app/pages/formations.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexKRS6vUqP7tMeta } from "/app/pages/liste-rdv/index.vue?macro=true";
import { default as _91id_93za2Ux7Dw60Meta } from "/app/pages/menu/[id].vue?macro=true";
import { default as index2zc2jUiOUrMeta } from "/app/pages/menu/add/index.vue?macro=true";
import { default as indexKEaHWmyZdKMeta } from "/app/pages/menu/index.vue?macro=true";
import { default as _91id_93viLy1osCf5Meta } from "/app/pages/modules/[id].vue?macro=true";
import { default as indexuahQCjcb2xMeta } from "/app/pages/modules/add/index.vue?macro=true";
import { default as indexhAKAs5PIqoMeta } from "/app/pages/modules/index.vue?macro=true";
import { default as indexj88iZwExVdMeta } from "/app/pages/notifications/index.vue?macro=true";
import { default as _91id_93Zj0lQwCKVZMeta } from "/app/pages/planning/accompagnement-appuie/[id].vue?macro=true";
import { default as index1RisrHifuKMeta } from "/app/pages/planning/accompagnement-appuie/index.vue?macro=true";
import { default as _91id_93HY2dcbcJYHMeta } from "/app/pages/planning/autres-visites/[id].vue?macro=true";
import { default as indexKh1j3fWnnAMeta } from "/app/pages/planning/autres-visites/index.vue?macro=true";
import { default as _91id_932zmihTN8oPMeta } from "/app/pages/planning/conge/[id].vue?macro=true";
import { default as indexGhmEDIEn5EMeta } from "/app/pages/planning/conge/index.vue?macro=true";
import { default as index25iGYkOZUaMeta } from "/app/pages/planning/formations/index.vue?macro=true";
import { default as _91id_93k6eR2xkDuzMeta } from "/app/pages/planning/garde/[id].vue?macro=true";
import { default as indexdv5Q16lOlHMeta } from "/app/pages/planning/garde/index.vue?macro=true";
import { default as _91id_93PScfK3fDECMeta } from "/app/pages/planning/persuasion/[id].vue?macro=true";
import { default as indexre7SoZz2uOMeta } from "/app/pages/planning/persuasion/index.vue?macro=true";
import { default as index8SeMq53kA8Meta } from "/app/pages/planning/sensibilisation/index.vue?macro=true";
import { default as indexsWADdbNUpgMeta } from "/app/pages/planning/vembauche/index.vue?macro=true";
import { default as _91id_93c4f4MKGQnGMeta } from "/app/pages/planning/vembauche/traiter/[id].vue?macro=true";
import { default as indexdRqujax1qyMeta } from "/app/pages/planning/vembauche/traiter/index.vue?macro=true";
import { default as indexz7VzH5MZOTMeta } from "/app/pages/planning/vets/index.vue?macro=true";
import { default as indexqiRlVmAypGMeta } from "/app/pages/planning/vmo/index.vue?macro=true";
import { default as indextgEnyypUFaMeta } from "/app/pages/profil/index.vue?macro=true";
import { default as indexoXx1CxXh3MMeta } from "/app/pages/rdv-specialist/index.vue?macro=true";
import { default as _91id_93KQajisX2MpMeta } from "/app/pages/reclamations/[id].vue?macro=true";
import { default as indexCirOZi8xQyMeta } from "/app/pages/reclamations/affilie/index.vue?macro=true";
import { default as _91id_931gBFj4oYGVMeta } from "/app/pages/reclamations/archive-in/[id].vue?macro=true";
import { default as index2hHgFWWEMIMeta } from "/app/pages/reclamations/archive-in/index.vue?macro=true";
import { default as indexfo9BAKQ7YOMeta } from "/app/pages/reclamations/index.vue?macro=true";
import { default as indexhOBcdtdQ4pMeta } from "/app/pages/register/index.vue?macro=true";
import { default as reglement_45adherentAfPf6BPl40Meta } from "/app/pages/reglement-adherent.vue?macro=true";
import { default as reglement_45interieur_45ostiehfIXlmbsAPMeta } from "/app/pages/reglement-interieur-ostie.vue?macro=true";
import { default as _91id_93q87PjBVG7xMeta } from "/app/pages/roles/[id].vue?macro=true";
import { default as indexzrTxjiQ8pGMeta } from "/app/pages/roles/add/index.vue?macro=true";
import { default as indexd33eSnEIJHMeta } from "/app/pages/roles/index.vue?macro=true";
import { default as securite9DEIbSVdZSMeta } from "/app/pages/securite.vue?macro=true";
import { default as index6BFMIvOGYXMeta } from "/app/pages/suggestions-affilie/index.vue?macro=true";
import { default as suggestionsuP0heK2rmqMeta } from "/app/pages/suggestions.vue?macro=true";
import { default as tableau_45bord_45reclamationsoPpKDjsrfFMeta } from "/app/pages/tableau-bord-reclamations.vue?macro=true";
import { default as _91id_93vDGLzaLEjmMeta } from "/app/pages/user-adherents/[id].vue?macro=true";
import { default as index66ZCqzKtV7Meta } from "/app/pages/user-adherents/index.vue?macro=true";
import { default as _91id_93nDQ2NKWll2Meta } from "/app/pages/users/[id].vue?macro=true";
import { default as _91id_93yLvL1V6HfdMeta } from "/app/pages/users/assigner/active/[id].vue?macro=true";
import { default as indexYySVLk13nRMeta } from "/app/pages/users/assigner/index.vue?macro=true";
import { default as passwordChangeNwRx5tg3vwMeta } from "/app/pages/users/component/passwordChange.vue?macro=true";
import { default as indexG1HlFdJ9R4Meta } from "/app/pages/users/create/index.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: indexDDpJLqPBhmMeta?.name ?? "action",
    path: indexDDpJLqPBhmMeta?.path ?? "/action",
    meta: indexDDpJLqPBhmMeta || {},
    alias: indexDDpJLqPBhmMeta?.alias || [],
    redirect: indexDDpJLqPBhmMeta?.redirect,
    component: () => import("/app/pages/action/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FlLm03CzZLMeta?.name ?? "adherents-id",
    path: _91id_93FlLm03CzZLMeta?.path ?? "/adherents/:id()",
    meta: _91id_93FlLm03CzZLMeta || {},
    alias: _91id_93FlLm03CzZLMeta?.alias || [],
    redirect: _91id_93FlLm03CzZLMeta?.redirect,
    component: () => import("/app/pages/adherents/[id].vue").then(m => m.default || m)
  },
  {
    name: indexbkX6wuincsMeta?.name ?? "adherents",
    path: indexbkX6wuincsMeta?.path ?? "/adherents",
    meta: indexbkX6wuincsMeta || {},
    alias: indexbkX6wuincsMeta?.alias || [],
    redirect: indexbkX6wuincsMeta?.redirect,
    component: () => import("/app/pages/adherents/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933RBKFoaHqYMeta?.name ?? "adhesion-id",
    path: _91id_933RBKFoaHqYMeta?.path ?? "/adhesion/:id()",
    meta: _91id_933RBKFoaHqYMeta || {},
    alias: _91id_933RBKFoaHqYMeta?.alias || [],
    redirect: _91id_933RBKFoaHqYMeta?.redirect,
    component: () => import("/app/pages/adhesion/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93zAVVOYUmEQMeta?.name ?? "adhesion-clotures-id",
    path: _91id_93zAVVOYUmEQMeta?.path ?? "/adhesion/clotures/:id()",
    meta: _91id_93zAVVOYUmEQMeta || {},
    alias: _91id_93zAVVOYUmEQMeta?.alias || [],
    redirect: _91id_93zAVVOYUmEQMeta?.redirect,
    component: () => import("/app/pages/adhesion/clotures/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGnzHNWwz3ZMeta?.name ?? "adhesion-clotures",
    path: indexGnzHNWwz3ZMeta?.path ?? "/adhesion/clotures",
    meta: indexGnzHNWwz3ZMeta || {},
    alias: indexGnzHNWwz3ZMeta?.alias || [],
    redirect: indexGnzHNWwz3ZMeta?.redirect,
    component: () => import("/app/pages/adhesion/clotures/index.vue").then(m => m.default || m)
  },
  {
    name: index8rXZatycdpMeta?.name ?? "adhesion",
    path: index8rXZatycdpMeta?.path ?? "/adhesion",
    meta: index8rXZatycdpMeta || {},
    alias: index8rXZatycdpMeta?.alias || [],
    redirect: index8rXZatycdpMeta?.redirect,
    component: () => import("/app/pages/adhesion/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OdxkGm08MkMeta?.name ?? "adhesion-traitements-id",
    path: _91id_93OdxkGm08MkMeta?.path ?? "/adhesion/traitements/:id()",
    meta: _91id_93OdxkGm08MkMeta || {},
    alias: _91id_93OdxkGm08MkMeta?.alias || [],
    redirect: _91id_93OdxkGm08MkMeta?.redirect,
    component: () => import("/app/pages/adhesion/traitements/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_9350QlC9OmNNMeta?.name ?? "adhesion-traitements-files-id",
    path: _91id_9350QlC9OmNNMeta?.path ?? "/adhesion/traitements/files/:id()",
    meta: _91id_9350QlC9OmNNMeta || {},
    alias: _91id_9350QlC9OmNNMeta?.alias || [],
    redirect: _91id_9350QlC9OmNNMeta?.redirect,
    component: () => import("/app/pages/adhesion/traitements/files/[id].vue").then(m => m.default || m)
  },
  {
    name: index0gBOPTgyXNMeta?.name ?? "adhesion-traitements",
    path: index0gBOPTgyXNMeta?.path ?? "/adhesion/traitements",
    meta: index0gBOPTgyXNMeta || {},
    alias: index0gBOPTgyXNMeta?.alias || [],
    redirect: index0gBOPTgyXNMeta?.redirect,
    component: () => import("/app/pages/adhesion/traitements/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fYSPh2mWKMMeta?.name ?? "adhesion-valides-id",
    path: _91id_93fYSPh2mWKMMeta?.path ?? "/adhesion/valides/:id()",
    meta: _91id_93fYSPh2mWKMMeta || {},
    alias: _91id_93fYSPh2mWKMMeta?.alias || [],
    redirect: _91id_93fYSPh2mWKMMeta?.redirect,
    component: () => import("/app/pages/adhesion/valides/[id].vue").then(m => m.default || m)
  },
  {
    name: index03PsXYp8W9Meta?.name ?? "adhesion-valides",
    path: index03PsXYp8W9Meta?.path ?? "/adhesion/valides",
    meta: index03PsXYp8W9Meta || {},
    alias: index03PsXYp8W9Meta?.alias || [],
    redirect: index03PsXYp8W9Meta?.redirect,
    component: () => import("/app/pages/adhesion/valides/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930KVdUhpH6VMeta?.name ?? "affectation-add-id",
    path: _91id_930KVdUhpH6VMeta?.path ?? "/affectation/add/:id()",
    meta: _91id_930KVdUhpH6VMeta || {},
    alias: _91id_930KVdUhpH6VMeta?.alias || [],
    redirect: _91id_930KVdUhpH6VMeta?.redirect,
    component: () => import("/app/pages/affectation/add/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93fK3mcHjL2oMeta?.name ?? "affectation-edit-id",
    path: _91id_93fK3mcHjL2oMeta?.path ?? "/affectation/edit/:id()",
    meta: _91id_93fK3mcHjL2oMeta || {},
    alias: _91id_93fK3mcHjL2oMeta?.alias || [],
    redirect: _91id_93fK3mcHjL2oMeta?.redirect,
    component: () => import("/app/pages/affectation/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzCTtATjEr7Meta?.name ?? "affectation",
    path: indexzCTtATjEr7Meta?.path ?? "/affectation",
    meta: indexzCTtATjEr7Meta || {},
    alias: indexzCTtATjEr7Meta?.alias || [],
    redirect: indexzCTtATjEr7Meta?.redirect,
    component: () => import("/app/pages/affectation/index.vue").then(m => m.default || m)
  },
  {
    name: indexMNOwxhSHQZMeta?.name ?? "affilies",
    path: indexMNOwxhSHQZMeta?.path ?? "/affilies",
    meta: indexMNOwxhSHQZMeta || {},
    alias: indexMNOwxhSHQZMeta?.alias || [],
    redirect: indexMNOwxhSHQZMeta?.redirect,
    component: () => import("/app/pages/affilies/index.vue").then(m => m.default || m)
  },
  {
    name: index62PVvB1lWsMeta?.name ?? "avis-affilie",
    path: index62PVvB1lWsMeta?.path ?? "/avis-affilie",
    meta: index62PVvB1lWsMeta || {},
    alias: index62PVvB1lWsMeta?.alias || [],
    redirect: index62PVvB1lWsMeta?.redirect,
    component: () => import("/app/pages/avis-affilie/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hq94yZmZ4dMeta?.name ?? "avis-embauche-debauche-id",
    path: _91id_93hq94yZmZ4dMeta?.path ?? "/avis-embauche-debauche/:id()",
    meta: _91id_93hq94yZmZ4dMeta || {},
    alias: _91id_93hq94yZmZ4dMeta?.alias || [],
    redirect: _91id_93hq94yZmZ4dMeta?.redirect,
    component: () => import("/app/pages/avis-embauche-debauche/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93a7ZZImUuyWMeta?.name ?? "avis-embauche-debauche-historique-ae-ed-id",
    path: _91id_93a7ZZImUuyWMeta?.path ?? "/avis-embauche-debauche/historique-ae-ed/:id()",
    meta: _91id_93a7ZZImUuyWMeta || {},
    alias: _91id_93a7ZZImUuyWMeta?.alias || [],
    redirect: _91id_93a7ZZImUuyWMeta?.redirect,
    component: () => import("/app/pages/avis-embauche-debauche/historique-ae-ed/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWo4LlXD7UsMeta?.name ?? "avis-embauche-debauche-historique-ae-ed",
    path: indexWo4LlXD7UsMeta?.path ?? "/avis-embauche-debauche/historique-ae-ed",
    meta: indexWo4LlXD7UsMeta || {},
    alias: indexWo4LlXD7UsMeta?.alias || [],
    redirect: indexWo4LlXD7UsMeta?.redirect,
    component: () => import("/app/pages/avis-embauche-debauche/historique-ae-ed/index.vue").then(m => m.default || m)
  },
  {
    name: indexlYolvEFBntMeta?.name ?? "avis-embauche-debauche",
    path: indexlYolvEFBntMeta?.path ?? "/avis-embauche-debauche",
    meta: indexlYolvEFBntMeta || {},
    alias: indexlYolvEFBntMeta?.alias || [],
    redirect: indexlYolvEFBntMeta?.redirect,
    component: () => import("/app/pages/avis-embauche-debauche/index.vue").then(m => m.default || m)
  },
  {
    name: index1cFf630yRDMeta?.name ?? "avis",
    path: index1cFf630yRDMeta?.path ?? "/avis",
    meta: index1cFf630yRDMeta || {},
    alias: index1cFf630yRDMeta?.alias || [],
    redirect: index1cFf630yRDMeta?.redirect,
    component: () => import("/app/pages/avis/index.vue").then(m => m.default || m)
  },
  {
    name: indexY9ODxP3l5eMeta?.name ?? "bulletin-consultation",
    path: indexY9ODxP3l5eMeta?.path ?? "/bulletin-consultation",
    meta: indexY9ODxP3l5eMeta || {},
    alias: indexY9ODxP3l5eMeta?.alias || [],
    redirect: indexY9ODxP3l5eMeta?.redirect,
    component: () => import("/app/pages/bulletin-consultation/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93X3Qj3bIzdZMeta?.name ?? "contenus-reglement-adherent-id",
    path: _91id_93X3Qj3bIzdZMeta?.path ?? "/contenus/reglement-adherent/:id()",
    meta: _91id_93X3Qj3bIzdZMeta || {},
    alias: _91id_93X3Qj3bIzdZMeta?.alias || [],
    redirect: _91id_93X3Qj3bIzdZMeta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93mVnACYD4dEMeta?.name ?? "contenus-reglement-adherent-archive-in-id",
    path: _91id_93mVnACYD4dEMeta?.path ?? "/contenus/reglement-adherent/archive-in/:id()",
    meta: _91id_93mVnACYD4dEMeta || {},
    alias: _91id_93mVnACYD4dEMeta?.alias || [],
    redirect: _91id_93mVnACYD4dEMeta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/archive-in/[id].vue").then(m => m.default || m)
  },
  {
    name: index7omLMih84yMeta?.name ?? "contenus-reglement-adherent-archive-in",
    path: index7omLMih84yMeta?.path ?? "/contenus/reglement-adherent/archive-in",
    meta: index7omLMih84yMeta || {},
    alias: index7omLMih84yMeta?.alias || [],
    redirect: index7omLMih84yMeta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/archive-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexSBXhEh5huLMeta?.name ?? "contenus-reglement-adherent-convocations",
    path: indexSBXhEh5huLMeta?.path ?? "/contenus/reglement-adherent/convocations",
    meta: indexSBXhEh5huLMeta || {},
    alias: indexSBXhEh5huLMeta?.alias || [],
    redirect: indexSBXhEh5huLMeta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/convocations/index.vue").then(m => m.default || m)
  },
  {
    name: index2tqyFOCN5aMeta?.name ?? "contenus-reglement-adherent-convocations-new",
    path: index2tqyFOCN5aMeta?.path ?? "/contenus/reglement-adherent/convocations/new",
    meta: index2tqyFOCN5aMeta || {},
    alias: index2tqyFOCN5aMeta?.alias || [],
    redirect: index2tqyFOCN5aMeta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/convocations/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexVVGhb8rWj8Meta?.name ?? "contenus-reglement-adherent",
    path: indexVVGhb8rWj8Meta?.path ?? "/contenus/reglement-adherent",
    meta: indexVVGhb8rWj8Meta || {},
    alias: indexVVGhb8rWj8Meta?.alias || [],
    redirect: indexVVGhb8rWj8Meta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/index.vue").then(m => m.default || m)
  },
  {
    name: index_40old_41vg71sXIloeMeta?.name ?? "contenus-reglement-adherent-index(old)",
    path: index_40old_41vg71sXIloeMeta?.path ?? "/contenus/reglement-adherent/index(old)",
    meta: index_40old_41vg71sXIloeMeta || {},
    alias: index_40old_41vg71sXIloeMeta?.alias || [],
    redirect: index_40old_41vg71sXIloeMeta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/index(old).vue").then(m => m.default || m)
  },
  {
    name: _91id_93Y1aMBXbu0WMeta?.name ?? "contenus-reglement-adherent-poster-id",
    path: _91id_93Y1aMBXbu0WMeta?.path ?? "/contenus/reglement-adherent/poster/:id()",
    meta: _91id_93Y1aMBXbu0WMeta || {},
    alias: _91id_93Y1aMBXbu0WMeta?.alias || [],
    redirect: _91id_93Y1aMBXbu0WMeta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/poster/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhi3IEy62RNMeta?.name ?? "contenus-reglement-adherent-poster",
    path: indexhi3IEy62RNMeta?.path ?? "/contenus/reglement-adherent/poster",
    meta: indexhi3IEy62RNMeta || {},
    alias: indexhi3IEy62RNMeta?.alias || [],
    redirect: indexhi3IEy62RNMeta?.redirect,
    component: () => import("/app/pages/contenus/reglement-adherent/poster/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard",
    path: dashboardJu37u7k0toMeta?.path ?? "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ES9H7ESlMuMeta?.name ?? "dns-id",
    path: _91id_93ES9H7ESlMuMeta?.path ?? "/dns/:id()",
    meta: _91id_93ES9H7ESlMuMeta || {},
    alias: _91id_93ES9H7ESlMuMeta?.alias || [],
    redirect: _91id_93ES9H7ESlMuMeta?.redirect,
    component: () => import("/app/pages/dns/[id].vue").then(m => m.default || m)
  },
  {
    name: fichierFinderLxnncYy5DDMeta?.name ?? "dns-fichiers-fichierFinder",
    path: fichierFinderLxnncYy5DDMeta?.path ?? "/dns/fichiers/fichierFinder",
    meta: fichierFinderLxnncYy5DDMeta || {},
    alias: fichierFinderLxnncYy5DDMeta?.alias || [],
    redirect: fichierFinderLxnncYy5DDMeta?.redirect,
    component: () => import("/app/pages/dns/fichiers/fichierFinder.vue").then(m => m.default || m)
  },
  {
    name: indexwPTjHBhu1LMeta?.name ?? "dns-fichiers",
    path: indexwPTjHBhu1LMeta?.path ?? "/dns/fichiers",
    meta: indexwPTjHBhu1LMeta || {},
    alias: indexwPTjHBhu1LMeta?.alias || [],
    redirect: indexwPTjHBhu1LMeta?.redirect,
    component: () => import("/app/pages/dns/fichiers/index.vue").then(m => m.default || m)
  },
  {
    name: indexZkGoeDWHl8Meta?.name ?? "dns",
    path: indexZkGoeDWHl8Meta?.path ?? "/dns",
    meta: indexZkGoeDWHl8Meta || {},
    alias: indexZkGoeDWHl8Meta?.alias || [],
    redirect: indexZkGoeDWHl8Meta?.redirect,
    component: () => import("/app/pages/dns/index.vue").then(m => m.default || m)
  },
  {
    name: indexeVBKvJGJUFMeta?.name ?? "dns-paiements",
    path: indexeVBKvJGJUFMeta?.path ?? "/dns/paiements",
    meta: indexeVBKvJGJUFMeta || {},
    alias: indexeVBKvJGJUFMeta?.alias || [],
    redirect: indexeVBKvJGJUFMeta?.redirect,
    component: () => import("/app/pages/dns/paiements/index.vue").then(m => m.default || m)
  },
  {
    name: indexsHANlbWIo1Meta?.name ?? "dns-relances",
    path: indexsHANlbWIo1Meta?.path ?? "/dns/relances",
    meta: indexsHANlbWIo1Meta || {},
    alias: indexsHANlbWIo1Meta?.alias || [],
    redirect: indexsHANlbWIo1Meta?.redirect,
    component: () => import("/app/pages/dns/relances/index.vue").then(m => m.default || m)
  },
  {
    name: index7DWpj8IqIMMeta?.name ?? "edition-fdr-articles-create",
    path: index7DWpj8IqIMMeta?.path ?? "/edition-fdr/articles/create",
    meta: index7DWpj8IqIMMeta || {},
    alias: index7DWpj8IqIMMeta?.alias || [],
    redirect: index7DWpj8IqIMMeta?.redirect,
    component: () => import("/app/pages/edition-fdr/articles/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexpAxxovZ16OMeta?.name ?? "edition-fdr-articles",
    path: indexpAxxovZ16OMeta?.path ?? "/edition-fdr/articles",
    meta: indexpAxxovZ16OMeta || {},
    alias: indexpAxxovZ16OMeta?.alias || [],
    redirect: indexpAxxovZ16OMeta?.redirect,
    component: () => import("/app/pages/edition-fdr/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_935jeapZcaJCMeta?.name ?? "edition-fdr-boite-risque-id",
    path: _91id_935jeapZcaJCMeta?.path ?? "/edition-fdr/boite-risque/:id()",
    meta: _91id_935jeapZcaJCMeta || {},
    alias: _91id_935jeapZcaJCMeta?.alias || [],
    redirect: _91id_935jeapZcaJCMeta?.redirect,
    component: () => import("/app/pages/edition-fdr/boite-risque/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93LQ68SWiJdfMeta?.name ?? "edition-fdr-boites-id",
    path: _91id_93LQ68SWiJdfMeta?.path ?? "/edition-fdr/boites/:id()",
    meta: _91id_93LQ68SWiJdfMeta || {},
    alias: _91id_93LQ68SWiJdfMeta?.alias || [],
    redirect: _91id_93LQ68SWiJdfMeta?.redirect,
    component: () => import("/app/pages/edition-fdr/boites/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GT9WynZdFKMeta?.name ?? "edition-fdr-facteur-diagnostique-id",
    path: _91id_93GT9WynZdFKMeta?.path ?? "/edition-fdr/facteur-diagnostique/:id()",
    meta: _91id_93GT9WynZdFKMeta || {},
    alias: _91id_93GT9WynZdFKMeta?.alias || [],
    redirect: _91id_93GT9WynZdFKMeta?.redirect,
    component: () => import("/app/pages/edition-fdr/facteur-diagnostique/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PEx6wLQONvMeta?.name ?? "edition-fdr-facteur-risque-id",
    path: _91id_93PEx6wLQONvMeta?.path ?? "/edition-fdr/facteur-risque/:id()",
    meta: _91id_93PEx6wLQONvMeta || {},
    alias: _91id_93PEx6wLQONvMeta?.alias || [],
    redirect: _91id_93PEx6wLQONvMeta?.redirect,
    component: () => import("/app/pages/edition-fdr/facteur-risque/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdwogxHYfH4Meta?.name ?? "edition-fdr",
    path: indexdwogxHYfH4Meta?.path ?? "/edition-fdr",
    meta: indexdwogxHYfH4Meta || {},
    alias: indexdwogxHYfH4Meta?.alias || [],
    redirect: indexdwogxHYfH4Meta?.redirect,
    component: () => import("/app/pages/edition-fdr/index.vue").then(m => m.default || m)
  },
  {
    name: editions_45fdrTC3tQRMNMIMeta?.name ?? "editions-fdr",
    path: editions_45fdrTC3tQRMNMIMeta?.path ?? "/editions-fdr",
    meta: editions_45fdrTC3tQRMNMIMeta || {},
    alias: editions_45fdrTC3tQRMNMIMeta?.alias || [],
    redirect: editions_45fdrTC3tQRMNMIMeta?.redirect,
    component: () => import("/app/pages/editions-fdr.vue").then(m => m.default || m)
  },
  {
    name: indexUh4D9BfWNsMeta?.name ?? "fdr-conseils",
    path: indexUh4D9BfWNsMeta?.path ?? "/fdr/conseils",
    meta: indexUh4D9BfWNsMeta || {},
    alias: indexUh4D9BfWNsMeta?.alias || [],
    redirect: indexUh4D9BfWNsMeta?.redirect,
    component: () => import("/app/pages/fdr/conseils/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934xz2e21JSpMeta?.name ?? "fdr-create-boite-id",
    path: _91id_934xz2e21JSpMeta?.path ?? "/fdr/create/boite/:id()",
    meta: _91id_934xz2e21JSpMeta || {},
    alias: _91id_934xz2e21JSpMeta?.alias || [],
    redirect: _91id_934xz2e21JSpMeta?.redirect,
    component: () => import("/app/pages/fdr/create/boite/[id].vue").then(m => m.default || m)
  },
  {
    name: _91idfdr_93iM65pwPyUMMeta?.name ?? "fdr-create-diagnostic-idfdr",
    path: _91idfdr_93iM65pwPyUMMeta?.path ?? "/fdr/create/diagnostic/:idfdr()",
    meta: _91idfdr_93iM65pwPyUMMeta || {},
    alias: _91idfdr_93iM65pwPyUMMeta?.alias || [],
    redirect: _91idfdr_93iM65pwPyUMMeta?.redirect,
    component: () => import("/app/pages/fdr/create/diagnostic/[idfdr].vue").then(m => m.default || m)
  },
  {
    name: _91id_93qVHDIhOUyvMeta?.name ?? "fdr-create-fdr-id",
    path: _91id_93qVHDIhOUyvMeta?.path ?? "/fdr/create/fdr/:id()",
    meta: _91id_93qVHDIhOUyvMeta || {},
    alias: _91id_93qVHDIhOUyvMeta?.alias || [],
    redirect: _91id_93qVHDIhOUyvMeta?.redirect,
    component: () => import("/app/pages/fdr/create/fdr/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Ty1hnLpC9XMeta?.name ?? "fdr-create-regle-id",
    path: _91id_93Ty1hnLpC9XMeta?.path ?? "/fdr/create/regle/:id()",
    meta: _91id_93Ty1hnLpC9XMeta || {},
    alias: _91id_93Ty1hnLpC9XMeta?.alias || [],
    redirect: _91id_93Ty1hnLpC9XMeta?.redirect,
    component: () => import("/app/pages/fdr/create/regle/[id].vue").then(m => m.default || m)
  },
  {
    name: indexE6FX2tPhldMeta?.name ?? "fdr-create-thematique",
    path: indexE6FX2tPhldMeta?.path ?? "/fdr/create/thematique",
    meta: indexE6FX2tPhldMeta || {},
    alias: indexE6FX2tPhldMeta?.alias || [],
    redirect: indexE6FX2tPhldMeta?.redirect,
    component: () => import("/app/pages/fdr/create/thematique/index.vue").then(m => m.default || m)
  },
  {
    name: _91idfdr_93ySZW4SoeNZMeta?.name ?? "fdr-diagnostic-idfdr",
    path: _91idfdr_93ySZW4SoeNZMeta?.path ?? "/fdr/diagnostic/:idfdr()",
    meta: _91idfdr_93ySZW4SoeNZMeta || {},
    alias: _91idfdr_93ySZW4SoeNZMeta?.alias || [],
    redirect: _91idfdr_93ySZW4SoeNZMeta?.redirect,
    component: () => import("/app/pages/fdr/diagnostic/[idfdr].vue").then(m => m.default || m)
  },
  {
    name: _91idthematique_93_45_91idfdr_93tUAlMkzbyNMeta?.name ?? "fdr-diagnostic-idthematique-idfdr",
    path: _91idthematique_93_45_91idfdr_93tUAlMkzbyNMeta?.path ?? "/fdr/diagnostic/:idthematique()-:idfdr()",
    meta: _91idthematique_93_45_91idfdr_93tUAlMkzbyNMeta || {},
    alias: _91idthematique_93_45_91idfdr_93tUAlMkzbyNMeta?.alias || [],
    redirect: _91idthematique_93_45_91idfdr_93tUAlMkzbyNMeta?.redirect,
    component: () => import("/app/pages/fdr/diagnostic/[idthematique]-[idfdr].vue").then(m => m.default || m)
  },
  {
    name: _91iddiagnostic_93lxs4LpnEPqMeta?.name ?? "fdr-diagnostic-create-iddiagnostic",
    path: _91iddiagnostic_93lxs4LpnEPqMeta?.path ?? "/fdr/diagnostic/create/:iddiagnostic()",
    meta: _91iddiagnostic_93lxs4LpnEPqMeta || {},
    alias: _91iddiagnostic_93lxs4LpnEPqMeta?.alias || [],
    redirect: _91iddiagnostic_93lxs4LpnEPqMeta?.redirect,
    component: () => import("/app/pages/fdr/diagnostic/create/[iddiagnostic].vue").then(m => m.default || m)
  },
  {
    name: _91iddiagnostic_93ENU0gYSjjMMeta?.name ?? "fdr-diagnostic-resiliation-iddiagnostic",
    path: _91iddiagnostic_93ENU0gYSjjMMeta?.path ?? "/fdr/diagnostic/resiliation/:iddiagnostic()",
    meta: _91iddiagnostic_93ENU0gYSjjMMeta || {},
    alias: _91iddiagnostic_93ENU0gYSjjMMeta?.alias || [],
    redirect: _91iddiagnostic_93ENU0gYSjjMMeta?.redirect,
    component: () => import("/app/pages/fdr/diagnostic/resiliation/[iddiagnostic].vue").then(m => m.default || m)
  },
  {
    name: indexRpy5j0t1ioMeta?.name ?? "fdr-fdr",
    path: indexRpy5j0t1ioMeta?.path ?? "/fdr/fdr",
    meta: indexRpy5j0t1ioMeta || {},
    alias: indexRpy5j0t1ioMeta?.alias || [],
    redirect: indexRpy5j0t1ioMeta?.redirect,
    component: () => import("/app/pages/fdr/fdr/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HjHsVP3n7cMeta?.name ?? "fdr-point-boite-id",
    path: _91id_93HjHsVP3n7cMeta?.path ?? "/fdr/point/boite/:id()",
    meta: _91id_93HjHsVP3n7cMeta || {},
    alias: _91id_93HjHsVP3n7cMeta?.alias || [],
    redirect: _91id_93HjHsVP3n7cMeta?.redirect,
    component: () => import("/app/pages/fdr/point/boite/[id].vue").then(m => m.default || m)
  },
  {
    name: _91idboite_93iBQyrYsHQIMeta?.name ?? "fdr-point-point-idboite",
    path: _91idboite_93iBQyrYsHQIMeta?.path ?? "/fdr/point/point/:idboite()",
    meta: _91idboite_93iBQyrYsHQIMeta || {},
    alias: _91idboite_93iBQyrYsHQIMeta?.alias || [],
    redirect: _91idboite_93iBQyrYsHQIMeta?.redirect,
    component: () => import("/app/pages/fdr/point/point/[idboite].vue").then(m => m.default || m)
  },
  {
    name: index8TXKUfDr9sMeta?.name ?? "fdr-point-thematique",
    path: index8TXKUfDr9sMeta?.path ?? "/fdr/point/thematique",
    meta: index8TXKUfDr9sMeta || {},
    alias: index8TXKUfDr9sMeta?.alias || [],
    redirect: index8TXKUfDr9sMeta?.redirect,
    component: () => import("/app/pages/fdr/point/thematique/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9322LatRXwFTMeta?.name ?? "fdr-read-boite-id",
    path: _91id_9322LatRXwFTMeta?.path ?? "/fdr/read/boite/:id()",
    meta: _91id_9322LatRXwFTMeta || {},
    alias: _91id_9322LatRXwFTMeta?.alias || [],
    redirect: _91id_9322LatRXwFTMeta?.redirect,
    component: () => import("/app/pages/fdr/read/boite/[id].vue").then(m => m.default || m)
  },
  {
    name: _91idfdr_93ZQLhLcHsq5Meta?.name ?? "fdr-read-diagnostic-idfdr",
    path: _91idfdr_93ZQLhLcHsq5Meta?.path ?? "/fdr/read/diagnostic/:idfdr()",
    meta: _91idfdr_93ZQLhLcHsq5Meta || {},
    alias: _91idfdr_93ZQLhLcHsq5Meta?.alias || [],
    redirect: _91idfdr_93ZQLhLcHsq5Meta?.redirect,
    component: () => import("/app/pages/fdr/read/diagnostic/[idfdr].vue").then(m => m.default || m)
  },
  {
    name: _91id_93E6VDNx88BHMeta?.name ?? "fdr-read-fdr-id",
    path: _91id_93E6VDNx88BHMeta?.path ?? "/fdr/read/fdr/:id()",
    meta: _91id_93E6VDNx88BHMeta || {},
    alias: _91id_93E6VDNx88BHMeta?.alias || [],
    redirect: _91id_93E6VDNx88BHMeta?.redirect,
    component: () => import("/app/pages/fdr/read/fdr/[id].vue").then(m => m.default || m)
  },
  {
    name: _91iddiag_93i3sBuiXA7hMeta?.name ?? "fdr-read-regle-iddiag",
    path: _91iddiag_93i3sBuiXA7hMeta?.path ?? "/fdr/read/regle/:iddiag()",
    meta: _91iddiag_93i3sBuiXA7hMeta || {},
    alias: _91iddiag_93i3sBuiXA7hMeta?.alias || [],
    redirect: _91iddiag_93i3sBuiXA7hMeta?.redirect,
    component: () => import("/app/pages/fdr/read/regle/[iddiag].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TxElgQ2VeaMeta?.name ?? "fdr-regle-id",
    path: _91id_93TxElgQ2VeaMeta?.path ?? "/fdr/regle/:id()",
    meta: _91id_93TxElgQ2VeaMeta || {},
    alias: _91id_93TxElgQ2VeaMeta?.alias || [],
    redirect: _91id_93TxElgQ2VeaMeta?.redirect,
    component: () => import("/app/pages/fdr/regle/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDHNDcclCD4Meta?.name ?? "fdr-simulation",
    path: indexDHNDcclCD4Meta?.path ?? "/fdr/simulation",
    meta: indexDHNDcclCD4Meta || {},
    alias: indexDHNDcclCD4Meta?.alias || [],
    redirect: indexDHNDcclCD4Meta?.redirect,
    component: () => import("/app/pages/fdr/simulation/index.vue").then(m => m.default || m)
  },
  {
    name: _91idthematique_93sWconIEA1bMeta?.name ?? "fdr-tableau-idthematique",
    path: _91idthematique_93sWconIEA1bMeta?.path ?? "/fdr/tableau/:idthematique()",
    meta: _91idthematique_93sWconIEA1bMeta || {},
    alias: _91idthematique_93sWconIEA1bMeta?.alias || [],
    redirect: _91idthematique_93sWconIEA1bMeta?.redirect,
    component: () => import("/app/pages/fdr/tableau/[idthematique].vue").then(m => m.default || m)
  },
  {
    name: _91id_93o18MsErAtEMeta?.name ?? "fdr-thematiques-id",
    path: _91id_93o18MsErAtEMeta?.path ?? "/fdr/thematiques/:id()",
    meta: _91id_93o18MsErAtEMeta || {},
    alias: _91id_93o18MsErAtEMeta?.alias || [],
    redirect: _91id_93o18MsErAtEMeta?.redirect,
    component: () => import("/app/pages/fdr/thematiques/[id].vue").then(m => m.default || m)
  },
  {
    name: fiches_45conseil0I2A9JOziLMeta?.name ?? "fiches-conseil",
    path: fiches_45conseil0I2A9JOziLMeta?.path ?? "/fiches-conseil",
    meta: fiches_45conseil0I2A9JOziLMeta || {},
    alias: fiches_45conseil0I2A9JOziLMeta?.alias || [],
    redirect: fiches_45conseil0I2A9JOziLMeta?.redirect,
    component: () => import("/app/pages/fiches-conseil.vue").then(m => m.default || m)
  },
  {
    name: indexIg9GPKq5r8Meta?.name ?? "file",
    path: indexIg9GPKq5r8Meta?.path ?? "/file",
    meta: indexIg9GPKq5r8Meta || {},
    alias: indexIg9GPKq5r8Meta?.alias || [],
    redirect: indexIg9GPKq5r8Meta?.redirect,
    component: () => import("/app/pages/file/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OjTdWVcZIlMeta?.name ?? "forgot-pwd-id",
    path: _91id_93OjTdWVcZIlMeta?.path ?? "/forgot-pwd/:id()",
    meta: _91id_93OjTdWVcZIlMeta || {},
    alias: _91id_93OjTdWVcZIlMeta?.alias || [],
    redirect: _91id_93OjTdWVcZIlMeta?.redirect,
    component: () => import("/app/pages/forgot-pwd/[id].vue").then(m => m.default || m)
  },
  {
    name: index_backup7feLI426f9Meta?.name ?? "forgot-pwd-index_backup",
    path: index_backup7feLI426f9Meta?.path ?? "/forgot-pwd/index_backup",
    meta: index_backup7feLI426f9Meta || {},
    alias: index_backup7feLI426f9Meta?.alias || [],
    redirect: index_backup7feLI426f9Meta?.redirect,
    component: () => import("/app/pages/forgot-pwd/index_backup.vue").then(m => m.default || m)
  },
  {
    name: indexCNvftuSas5Meta?.name ?? "forgot-pwd",
    path: indexCNvftuSas5Meta?.path ?? "/forgot-pwd",
    meta: indexCNvftuSas5Meta || {},
    alias: indexCNvftuSas5Meta?.alias || [],
    redirect: indexCNvftuSas5Meta?.redirect,
    component: () => import("/app/pages/forgot-pwd/index.vue").then(m => m.default || m)
  },
  {
    name: formationsF6ti9mewdHMeta?.name ?? "formations",
    path: formationsF6ti9mewdHMeta?.path ?? "/formations",
    meta: formationsF6ti9mewdHMeta || {},
    alias: formationsF6ti9mewdHMeta?.alias || [],
    redirect: formationsF6ti9mewdHMeta?.redirect,
    component: () => import("/app/pages/formations.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexKRS6vUqP7tMeta?.name ?? "liste-rdv",
    path: indexKRS6vUqP7tMeta?.path ?? "/liste-rdv",
    meta: indexKRS6vUqP7tMeta || {},
    alias: indexKRS6vUqP7tMeta?.alias || [],
    redirect: indexKRS6vUqP7tMeta?.redirect,
    component: () => import("/app/pages/liste-rdv/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93za2Ux7Dw60Meta?.name ?? "menu-id",
    path: _91id_93za2Ux7Dw60Meta?.path ?? "/menu/:id()",
    meta: _91id_93za2Ux7Dw60Meta || {},
    alias: _91id_93za2Ux7Dw60Meta?.alias || [],
    redirect: _91id_93za2Ux7Dw60Meta?.redirect,
    component: () => import("/app/pages/menu/[id].vue").then(m => m.default || m)
  },
  {
    name: index2zc2jUiOUrMeta?.name ?? "menu-add",
    path: index2zc2jUiOUrMeta?.path ?? "/menu/add",
    meta: index2zc2jUiOUrMeta || {},
    alias: index2zc2jUiOUrMeta?.alias || [],
    redirect: index2zc2jUiOUrMeta?.redirect,
    component: () => import("/app/pages/menu/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexKEaHWmyZdKMeta?.name ?? "menu",
    path: indexKEaHWmyZdKMeta?.path ?? "/menu",
    meta: indexKEaHWmyZdKMeta || {},
    alias: indexKEaHWmyZdKMeta?.alias || [],
    redirect: indexKEaHWmyZdKMeta?.redirect,
    component: () => import("/app/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93viLy1osCf5Meta?.name ?? "modules-id",
    path: _91id_93viLy1osCf5Meta?.path ?? "/modules/:id()",
    meta: _91id_93viLy1osCf5Meta || {},
    alias: _91id_93viLy1osCf5Meta?.alias || [],
    redirect: _91id_93viLy1osCf5Meta?.redirect,
    component: () => import("/app/pages/modules/[id].vue").then(m => m.default || m)
  },
  {
    name: indexuahQCjcb2xMeta?.name ?? "modules-add",
    path: indexuahQCjcb2xMeta?.path ?? "/modules/add",
    meta: indexuahQCjcb2xMeta || {},
    alias: indexuahQCjcb2xMeta?.alias || [],
    redirect: indexuahQCjcb2xMeta?.redirect,
    component: () => import("/app/pages/modules/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexhAKAs5PIqoMeta?.name ?? "modules",
    path: indexhAKAs5PIqoMeta?.path ?? "/modules",
    meta: indexhAKAs5PIqoMeta || {},
    alias: indexhAKAs5PIqoMeta?.alias || [],
    redirect: indexhAKAs5PIqoMeta?.redirect,
    component: () => import("/app/pages/modules/index.vue").then(m => m.default || m)
  },
  {
    name: indexj88iZwExVdMeta?.name ?? "notifications",
    path: indexj88iZwExVdMeta?.path ?? "/notifications",
    meta: indexj88iZwExVdMeta || {},
    alias: indexj88iZwExVdMeta?.alias || [],
    redirect: indexj88iZwExVdMeta?.redirect,
    component: () => import("/app/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Zj0lQwCKVZMeta?.name ?? "planning-accompagnement-appuie-id",
    path: _91id_93Zj0lQwCKVZMeta?.path ?? "/planning/accompagnement-appuie/:id()",
    meta: _91id_93Zj0lQwCKVZMeta || {},
    alias: _91id_93Zj0lQwCKVZMeta?.alias || [],
    redirect: _91id_93Zj0lQwCKVZMeta?.redirect,
    component: () => import("/app/pages/planning/accompagnement-appuie/[id].vue").then(m => m.default || m)
  },
  {
    name: index1RisrHifuKMeta?.name ?? "planning-accompagnement-appuie",
    path: index1RisrHifuKMeta?.path ?? "/planning/accompagnement-appuie",
    meta: index1RisrHifuKMeta || {},
    alias: index1RisrHifuKMeta?.alias || [],
    redirect: index1RisrHifuKMeta?.redirect,
    component: () => import("/app/pages/planning/accompagnement-appuie/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HY2dcbcJYHMeta?.name ?? "planning-autres-visites-id",
    path: _91id_93HY2dcbcJYHMeta?.path ?? "/planning/autres-visites/:id()",
    meta: _91id_93HY2dcbcJYHMeta || {},
    alias: _91id_93HY2dcbcJYHMeta?.alias || [],
    redirect: _91id_93HY2dcbcJYHMeta?.redirect,
    component: () => import("/app/pages/planning/autres-visites/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKh1j3fWnnAMeta?.name ?? "planning-autres-visites",
    path: indexKh1j3fWnnAMeta?.path ?? "/planning/autres-visites",
    meta: indexKh1j3fWnnAMeta || {},
    alias: indexKh1j3fWnnAMeta?.alias || [],
    redirect: indexKh1j3fWnnAMeta?.redirect,
    component: () => import("/app/pages/planning/autres-visites/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_932zmihTN8oPMeta?.name ?? "planning-conge-id",
    path: _91id_932zmihTN8oPMeta?.path ?? "/planning/conge/:id()",
    meta: _91id_932zmihTN8oPMeta || {},
    alias: _91id_932zmihTN8oPMeta?.alias || [],
    redirect: _91id_932zmihTN8oPMeta?.redirect,
    component: () => import("/app/pages/planning/conge/[id].vue").then(m => m.default || m)
  },
  {
    name: indexGhmEDIEn5EMeta?.name ?? "planning-conge",
    path: indexGhmEDIEn5EMeta?.path ?? "/planning/conge",
    meta: indexGhmEDIEn5EMeta || {},
    alias: indexGhmEDIEn5EMeta?.alias || [],
    redirect: indexGhmEDIEn5EMeta?.redirect,
    component: () => import("/app/pages/planning/conge/index.vue").then(m => m.default || m)
  },
  {
    name: index25iGYkOZUaMeta?.name ?? "planning-formations",
    path: index25iGYkOZUaMeta?.path ?? "/planning/formations",
    meta: index25iGYkOZUaMeta || {},
    alias: index25iGYkOZUaMeta?.alias || [],
    redirect: index25iGYkOZUaMeta?.redirect,
    component: () => import("/app/pages/planning/formations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93k6eR2xkDuzMeta?.name ?? "planning-garde-id",
    path: _91id_93k6eR2xkDuzMeta?.path ?? "/planning/garde/:id()",
    meta: _91id_93k6eR2xkDuzMeta || {},
    alias: _91id_93k6eR2xkDuzMeta?.alias || [],
    redirect: _91id_93k6eR2xkDuzMeta?.redirect,
    component: () => import("/app/pages/planning/garde/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdv5Q16lOlHMeta?.name ?? "planning-garde",
    path: indexdv5Q16lOlHMeta?.path ?? "/planning/garde",
    meta: indexdv5Q16lOlHMeta || {},
    alias: indexdv5Q16lOlHMeta?.alias || [],
    redirect: indexdv5Q16lOlHMeta?.redirect,
    component: () => import("/app/pages/planning/garde/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PScfK3fDECMeta?.name ?? "planning-persuasion-id",
    path: _91id_93PScfK3fDECMeta?.path ?? "/planning/persuasion/:id()",
    meta: _91id_93PScfK3fDECMeta || {},
    alias: _91id_93PScfK3fDECMeta?.alias || [],
    redirect: _91id_93PScfK3fDECMeta?.redirect,
    component: () => import("/app/pages/planning/persuasion/[id].vue").then(m => m.default || m)
  },
  {
    name: indexre7SoZz2uOMeta?.name ?? "planning-persuasion",
    path: indexre7SoZz2uOMeta?.path ?? "/planning/persuasion",
    meta: indexre7SoZz2uOMeta || {},
    alias: indexre7SoZz2uOMeta?.alias || [],
    redirect: indexre7SoZz2uOMeta?.redirect,
    component: () => import("/app/pages/planning/persuasion/index.vue").then(m => m.default || m)
  },
  {
    name: index8SeMq53kA8Meta?.name ?? "planning-sensibilisation",
    path: index8SeMq53kA8Meta?.path ?? "/planning/sensibilisation",
    meta: index8SeMq53kA8Meta || {},
    alias: index8SeMq53kA8Meta?.alias || [],
    redirect: index8SeMq53kA8Meta?.redirect,
    component: () => import("/app/pages/planning/sensibilisation/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWADdbNUpgMeta?.name ?? "planning-vembauche",
    path: indexsWADdbNUpgMeta?.path ?? "/planning/vembauche",
    meta: indexsWADdbNUpgMeta || {},
    alias: indexsWADdbNUpgMeta?.alias || [],
    redirect: indexsWADdbNUpgMeta?.redirect,
    component: () => import("/app/pages/planning/vembauche/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93c4f4MKGQnGMeta?.name ?? "planning-vembauche-traiter-id",
    path: _91id_93c4f4MKGQnGMeta?.path ?? "/planning/vembauche/traiter/:id()",
    meta: _91id_93c4f4MKGQnGMeta || {},
    alias: _91id_93c4f4MKGQnGMeta?.alias || [],
    redirect: _91id_93c4f4MKGQnGMeta?.redirect,
    component: () => import("/app/pages/planning/vembauche/traiter/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdRqujax1qyMeta?.name ?? "planning-vembauche-traiter",
    path: indexdRqujax1qyMeta?.path ?? "/planning/vembauche/traiter",
    meta: indexdRqujax1qyMeta || {},
    alias: indexdRqujax1qyMeta?.alias || [],
    redirect: indexdRqujax1qyMeta?.redirect,
    component: () => import("/app/pages/planning/vembauche/traiter/index.vue").then(m => m.default || m)
  },
  {
    name: indexz7VzH5MZOTMeta?.name ?? "planning-vets",
    path: indexz7VzH5MZOTMeta?.path ?? "/planning/vets",
    meta: indexz7VzH5MZOTMeta || {},
    alias: indexz7VzH5MZOTMeta?.alias || [],
    redirect: indexz7VzH5MZOTMeta?.redirect,
    component: () => import("/app/pages/planning/vets/index.vue").then(m => m.default || m)
  },
  {
    name: indexqiRlVmAypGMeta?.name ?? "planning-vmo",
    path: indexqiRlVmAypGMeta?.path ?? "/planning/vmo",
    meta: indexqiRlVmAypGMeta || {},
    alias: indexqiRlVmAypGMeta?.alias || [],
    redirect: indexqiRlVmAypGMeta?.redirect,
    component: () => import("/app/pages/planning/vmo/index.vue").then(m => m.default || m)
  },
  {
    name: indextgEnyypUFaMeta?.name ?? "profil",
    path: indextgEnyypUFaMeta?.path ?? "/profil",
    meta: indextgEnyypUFaMeta || {},
    alias: indextgEnyypUFaMeta?.alias || [],
    redirect: indextgEnyypUFaMeta?.redirect,
    component: () => import("/app/pages/profil/index.vue").then(m => m.default || m)
  },
  {
    name: indexoXx1CxXh3MMeta?.name ?? "rdv-specialist",
    path: indexoXx1CxXh3MMeta?.path ?? "/rdv-specialist",
    meta: indexoXx1CxXh3MMeta || {},
    alias: indexoXx1CxXh3MMeta?.alias || [],
    redirect: indexoXx1CxXh3MMeta?.redirect,
    component: () => import("/app/pages/rdv-specialist/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KQajisX2MpMeta?.name ?? "reclamations-id",
    path: _91id_93KQajisX2MpMeta?.path ?? "/reclamations/:id()",
    meta: _91id_93KQajisX2MpMeta || {},
    alias: _91id_93KQajisX2MpMeta?.alias || [],
    redirect: _91id_93KQajisX2MpMeta?.redirect,
    component: () => import("/app/pages/reclamations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexCirOZi8xQyMeta?.name ?? "reclamations-affilie",
    path: indexCirOZi8xQyMeta?.path ?? "/reclamations/affilie",
    meta: indexCirOZi8xQyMeta || {},
    alias: indexCirOZi8xQyMeta?.alias || [],
    redirect: indexCirOZi8xQyMeta?.redirect,
    component: () => import("/app/pages/reclamations/affilie/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931gBFj4oYGVMeta?.name ?? "reclamations-archive-in-id",
    path: _91id_931gBFj4oYGVMeta?.path ?? "/reclamations/archive-in/:id()",
    meta: _91id_931gBFj4oYGVMeta || {},
    alias: _91id_931gBFj4oYGVMeta?.alias || [],
    redirect: _91id_931gBFj4oYGVMeta?.redirect,
    component: () => import("/app/pages/reclamations/archive-in/[id].vue").then(m => m.default || m)
  },
  {
    name: index2hHgFWWEMIMeta?.name ?? "reclamations-archive-in",
    path: index2hHgFWWEMIMeta?.path ?? "/reclamations/archive-in",
    meta: index2hHgFWWEMIMeta || {},
    alias: index2hHgFWWEMIMeta?.alias || [],
    redirect: index2hHgFWWEMIMeta?.redirect,
    component: () => import("/app/pages/reclamations/archive-in/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo9BAKQ7YOMeta?.name ?? "reclamations",
    path: indexfo9BAKQ7YOMeta?.path ?? "/reclamations",
    meta: indexfo9BAKQ7YOMeta || {},
    alias: indexfo9BAKQ7YOMeta?.alias || [],
    redirect: indexfo9BAKQ7YOMeta?.redirect,
    component: () => import("/app/pages/reclamations/index.vue").then(m => m.default || m)
  },
  {
    name: indexhOBcdtdQ4pMeta?.name ?? "register",
    path: indexhOBcdtdQ4pMeta?.path ?? "/register",
    meta: indexhOBcdtdQ4pMeta || {},
    alias: indexhOBcdtdQ4pMeta?.alias || [],
    redirect: indexhOBcdtdQ4pMeta?.redirect,
    component: () => import("/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: reglement_45adherentAfPf6BPl40Meta?.name ?? "reglement-adherent",
    path: reglement_45adherentAfPf6BPl40Meta?.path ?? "/reglement-adherent",
    meta: reglement_45adherentAfPf6BPl40Meta || {},
    alias: reglement_45adherentAfPf6BPl40Meta?.alias || [],
    redirect: reglement_45adherentAfPf6BPl40Meta?.redirect,
    component: () => import("/app/pages/reglement-adherent.vue").then(m => m.default || m)
  },
  {
    name: reglement_45interieur_45ostiehfIXlmbsAPMeta?.name ?? "reglement-interieur-ostie",
    path: reglement_45interieur_45ostiehfIXlmbsAPMeta?.path ?? "/reglement-interieur-ostie",
    meta: reglement_45interieur_45ostiehfIXlmbsAPMeta || {},
    alias: reglement_45interieur_45ostiehfIXlmbsAPMeta?.alias || [],
    redirect: reglement_45interieur_45ostiehfIXlmbsAPMeta?.redirect,
    component: () => import("/app/pages/reglement-interieur-ostie.vue").then(m => m.default || m)
  },
  {
    name: _91id_93q87PjBVG7xMeta?.name ?? "roles-id",
    path: _91id_93q87PjBVG7xMeta?.path ?? "/roles/:id()",
    meta: _91id_93q87PjBVG7xMeta || {},
    alias: _91id_93q87PjBVG7xMeta?.alias || [],
    redirect: _91id_93q87PjBVG7xMeta?.redirect,
    component: () => import("/app/pages/roles/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzrTxjiQ8pGMeta?.name ?? "roles-add",
    path: indexzrTxjiQ8pGMeta?.path ?? "/roles/add",
    meta: indexzrTxjiQ8pGMeta || {},
    alias: indexzrTxjiQ8pGMeta?.alias || [],
    redirect: indexzrTxjiQ8pGMeta?.redirect,
    component: () => import("/app/pages/roles/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexd33eSnEIJHMeta?.name ?? "roles",
    path: indexd33eSnEIJHMeta?.path ?? "/roles",
    meta: indexd33eSnEIJHMeta || {},
    alias: indexd33eSnEIJHMeta?.alias || [],
    redirect: indexd33eSnEIJHMeta?.redirect,
    component: () => import("/app/pages/roles/index.vue").then(m => m.default || m)
  },
  {
    name: securite9DEIbSVdZSMeta?.name ?? "securite",
    path: securite9DEIbSVdZSMeta?.path ?? "/securite",
    meta: securite9DEIbSVdZSMeta || {},
    alias: securite9DEIbSVdZSMeta?.alias || [],
    redirect: securite9DEIbSVdZSMeta?.redirect,
    component: () => import("/app/pages/securite.vue").then(m => m.default || m)
  },
  {
    name: index6BFMIvOGYXMeta?.name ?? "suggestions-affilie",
    path: index6BFMIvOGYXMeta?.path ?? "/suggestions-affilie",
    meta: index6BFMIvOGYXMeta || {},
    alias: index6BFMIvOGYXMeta?.alias || [],
    redirect: index6BFMIvOGYXMeta?.redirect,
    component: () => import("/app/pages/suggestions-affilie/index.vue").then(m => m.default || m)
  },
  {
    name: suggestionsuP0heK2rmqMeta?.name ?? "suggestions",
    path: suggestionsuP0heK2rmqMeta?.path ?? "/suggestions",
    meta: suggestionsuP0heK2rmqMeta || {},
    alias: suggestionsuP0heK2rmqMeta?.alias || [],
    redirect: suggestionsuP0heK2rmqMeta?.redirect,
    component: () => import("/app/pages/suggestions.vue").then(m => m.default || m)
  },
  {
    name: tableau_45bord_45reclamationsoPpKDjsrfFMeta?.name ?? "tableau-bord-reclamations",
    path: tableau_45bord_45reclamationsoPpKDjsrfFMeta?.path ?? "/tableau-bord-reclamations",
    meta: tableau_45bord_45reclamationsoPpKDjsrfFMeta || {},
    alias: tableau_45bord_45reclamationsoPpKDjsrfFMeta?.alias || [],
    redirect: tableau_45bord_45reclamationsoPpKDjsrfFMeta?.redirect,
    component: () => import("/app/pages/tableau-bord-reclamations.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vDGLzaLEjmMeta?.name ?? "user-adherents-id",
    path: _91id_93vDGLzaLEjmMeta?.path ?? "/user-adherents/:id()",
    meta: _91id_93vDGLzaLEjmMeta || {},
    alias: _91id_93vDGLzaLEjmMeta?.alias || [],
    redirect: _91id_93vDGLzaLEjmMeta?.redirect,
    component: () => import("/app/pages/user-adherents/[id].vue").then(m => m.default || m)
  },
  {
    name: index66ZCqzKtV7Meta?.name ?? "user-adherents",
    path: index66ZCqzKtV7Meta?.path ?? "/user-adherents",
    meta: index66ZCqzKtV7Meta || {},
    alias: index66ZCqzKtV7Meta?.alias || [],
    redirect: index66ZCqzKtV7Meta?.redirect,
    component: () => import("/app/pages/user-adherents/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nDQ2NKWll2Meta?.name ?? "users-id",
    path: _91id_93nDQ2NKWll2Meta?.path ?? "/users/:id()",
    meta: _91id_93nDQ2NKWll2Meta || {},
    alias: _91id_93nDQ2NKWll2Meta?.alias || [],
    redirect: _91id_93nDQ2NKWll2Meta?.redirect,
    component: () => import("/app/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yLvL1V6HfdMeta?.name ?? "users-assigner-active-id",
    path: _91id_93yLvL1V6HfdMeta?.path ?? "/users/assigner/active/:id()",
    meta: _91id_93yLvL1V6HfdMeta || {},
    alias: _91id_93yLvL1V6HfdMeta?.alias || [],
    redirect: _91id_93yLvL1V6HfdMeta?.redirect,
    component: () => import("/app/pages/users/assigner/active/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYySVLk13nRMeta?.name ?? "users-assigner",
    path: indexYySVLk13nRMeta?.path ?? "/users/assigner",
    meta: indexYySVLk13nRMeta || {},
    alias: indexYySVLk13nRMeta?.alias || [],
    redirect: indexYySVLk13nRMeta?.redirect,
    component: () => import("/app/pages/users/assigner/index.vue").then(m => m.default || m)
  },
  {
    name: passwordChangeNwRx5tg3vwMeta?.name ?? "users-component-passwordChange",
    path: passwordChangeNwRx5tg3vwMeta?.path ?? "/users/component/passwordChange",
    meta: passwordChangeNwRx5tg3vwMeta || {},
    alias: passwordChangeNwRx5tg3vwMeta?.alias || [],
    redirect: passwordChangeNwRx5tg3vwMeta?.redirect,
    component: () => import("/app/pages/users/component/passwordChange.vue").then(m => m.default || m)
  },
  {
    name: indexG1HlFdJ9R4Meta?.name ?? "users-create",
    path: indexG1HlFdJ9R4Meta?.path ?? "/users/create",
    meta: indexG1HlFdJ9R4Meta || {},
    alias: indexG1HlFdJ9R4Meta?.alias || [],
    redirect: indexG1HlFdJ9R4Meta?.redirect,
    component: () => import("/app/pages/users/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexnq54o81oozMeta?.name ?? "users",
    path: indexnq54o81oozMeta?.path ?? "/users",
    meta: indexnq54o81oozMeta || {},
    alias: indexnq54o81oozMeta?.alias || [],
    redirect: indexnq54o81oozMeta?.redirect,
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  }
]